import React from "react";
import { usePageReference } from "modules/unions/usePageReference";

import PageLink, { PageLinkProps } from "components/Header/Navigation/NavigationItem/PageLink";

import * as styles from "./styles.module.scss";

export type PageNavigationProps = {
  reference: {
    items: PageLinkProps[];
  }[];
};

const PageNavigation: React.FC<PageNavigationProps> = ({ reference }) => {
  return (
    <div className={styles.pageNavigation}>
      <div className="container small">
        <nav>
          <ul>
            {reference[0]?.items.map((item: any, key: any) => {
              return (
                <PageLink
                  className={styles.link}
                  key={key}
                  label={item.page_link.title}
                  slug={usePageReference(item.page_link.reference[0])}
                  closeMenuHandler={() => null}
                />
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default PageNavigation;
