import React from "react";
import { graphql } from "gatsby";

import Meta from "components/Meta";
import Layout from "components/Layout";
import GenericRenderComponents from "components/GenericRenderComponents";

import { HeroBannerProps } from "components/Hero/Banner";
import { HeroCarouselProps } from "components/Hero/Carousel";
import { TextWithSubheadingProps } from "components/TextWithSubheading";
import { DegreesBannerSmallProps } from "components/SixtyThreeDegreesBanner/Small";
import { DegreesBannerMediumProps } from "components/SixtyThreeDegreesBanner/Medium";
import { DegreesBannerLargeProps } from "components/SixtyThreeDegreesBanner/Large";
import { ProductCardsProps } from "components/ProductCards/types";
import { DocDownloadProps } from "components/DocDownload";
import { ImageWithCaptionProps } from "components/ImageWithCaption";
import { StatProps } from "components/Stats/Stat";
import { TestimonialsProps } from "components/Testimonials";
import { PageNavigationProps } from "components/PageNavigation";
import { RelatedContentProps } from "components/RelatedContent";
import { NewsListPreviewProps } from "components/NewsList/Preview";
import { PublicationShowcaseProps } from "components/Publication/types";
import { TwoColumnImageProps } from "components/TwoColumnImage/types";
import { AccordionProps } from "components/Accordion";
import { FindATrainerProps } from "components/FindATrainer";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";
import { SegmentSelectorProps } from "components/SegmentSelector";
import { DefaultMetaType, MetaType } from "modules/meta/types";
import { FontType } from "components/Meta/constants";
import { useBreadcrumbs } from "modules/breadcrumbs/hooks";
import { NewsletterPopupType } from "modules/newsletter/types";

type GenericPageProps = { data: QueryTypes; location: any };

type QueryTypes = {
  allGenericPages: {
    edges: {
      node: {
        title: string;
      };
    }[];
  };
  contentstackGlobalMeta: DefaultMetaType;
  contentstackNewsletterPopup: NewsletterPopupType;
  contentstackAxGenericPage: {
    title: string;
    meta: MetaType;
    newsletter: {
      show_popup_page: string;
    };
    page_widgets: {
      document_download: DocDownloadProps;
      documents_download: DocDownloadProps;
      hero_banner: HeroBannerProps;
      hero_carousel: HeroCarouselProps;
      image_with_caption: ImageWithCaptionProps;
      large_degrees_banner: DegreesBannerLargeProps;
      medium_degrees_banner: DegreesBannerMediumProps;
      news_listing_preview: NewsListPreviewProps;
      page_navigation: PageNavigationProps;
      product_cards: ProductCardsProps;
      related_content: RelatedContentProps;
      small_degrees_banner: DegreesBannerSmallProps;
      stats: StatProps;
      testimonials: TestimonialsProps;
      text_with_subeading: TextWithSubheadingProps;
      publicationShowcase: PublicationShowcaseProps;
      twoColumnImage: TwoColumnImageProps;
      accordion: AccordionProps;
      findATrainer: FindATrainerProps;
      segment_selector: SegmentSelectorProps;
    }[];
  };
};

const GenericPage: React.FC<GenericPageProps> = ({ data, location }) => {
  /* Newsletter Popup */
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.contentstackAxGenericPage;

  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    submitted: newsletter?.submitted,
    pageOverride: page?.newsletter?.show_popup_page,
  });

  const { breadcrumbs } = useBreadcrumbs(location);

  return (
    <Layout>
      <Meta
        defaultMeta={data.contentstackGlobalMeta}
        location={location}
        meta={data.contentstackAxGenericPage.meta}
        preloadFonts={[FontType.PRIMARY_REGULAR, FontType.SECONDARY_SEMIBOLD]}
      />
      <div>
        {data?.contentstackAxGenericPage.page_widgets && (
          <GenericRenderComponents
            components={data?.contentstackAxGenericPage.page_widgets}
            title={data?.contentstackAxGenericPage.title}
            crumbs={breadcrumbs}
          />
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    allGenericPages: allContentstackAxGenericPage {
      edges {
        node {
          title
        }
      }
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackAxGenericPage(id: { eq: $id }) {
      id
      title
      ...metaGenericPage
      newsletter {
        show_popup_page
      }
      page_widgets {
        ...heroBanner
        ...heroCarousel
        ...productCards
        ...textWithSubheading
        ...stats
        ...testimonialsSection
        ...degreesBannerSmall
        ...degreesBannerMedium
        ...degreesBannerLarge
        ...onPageNavigation
        ...documentDownload
        ...documentsDownload
        ...imageWithCaption
        ...relatedContentGenericPage
        ...newsListingPreviewGenericPage
        ...publicationShowcaseGenericPage
        ...twoColumnImageGenericPage
        ...accordionGeneric
        ...findATrainerGeneric
        ...segmentSelectorGenericPage
      }
    }
  }
`;

export default React.memo(GenericPage);
