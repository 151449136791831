import React, { useMemo } from "react";
import loadableVisibility from "react-loadable-visibility/loadable-components";

import HeroBanner from "./Hero/Banner";
import HeroCarousel from "./Hero/Carousel";
import SegmentSelector, { SegmentSelectorProps } from "components/SegmentSelector";
import { HeroBannerProps } from "components/Hero/Banner";
import { HeroCarouselProps } from "components/Hero/Carousel";
import DocDownloadSkeleton from "./DocDownload/Skeleton";
import { DocDownloadProps } from "./DocDownload";
import Medium, { DegreesBannerMediumProps } from "components/SixtyThreeDegreesBanner/Medium";
import SixtyThreeDegreesBannerMediumSkeleton from "./SixtyThreeDegreesBanner/Medium/Skeleton";
import { TestimonialsProps } from "components/Testimonials";
import TestimonialsSkeleton from "./Testimonials/Skeleton";
import { RelatedContentProps } from "components/RelatedContent";
import { TwoColumnImageProps } from "./TwoColumnImage/types";
import TextWithSubheading, { TextWithSubheadingProps } from "components/TextWithSubheading";
import TextWithSubheadingSkeleton from "./TextWithSubheading/Skeleton";
import { StatsProps } from "components/Stats";
import StatsSkeleton from "./Stats/Skeleton";
import { PublicationShowcaseProps } from "./Publication/types";
import PublicationShowcaseSkeleton from "./Publication/Showcase/Skeleton";
import { ProductCardsProps } from "./ProductCards/types";
import Small, { DegreesBannerSmallProps } from "components/SixtyThreeDegreesBanner/Small";
import Large, { DegreesBannerLargeProps } from "components/SixtyThreeDegreesBanner/Large";
import { NewsListPreviewProps } from "components/NewsList/Preview";
import { ImageWithCaptionProps } from "components/ImageWithCaption";
import { FindATrainerProps } from "components/FindATrainer";
import { AccordionProps } from "components/Accordion";
import ProductCardsSkeleton from "./ProductCards/Skeleton";
import SixtyThreeDegreesBannerSmallSkeleton from "./SixtyThreeDegreesBanner/Small/Skeleton";
import SixtyThreeDegreesBannerLargeSkeleton from "./SixtyThreeDegreesBanner/Large/Skeleton";
import AccordionSkeleton from "./Accordion/Skeleton";
import TwoColumnImageSkeleton from "./TwoColumnImage/Skeleton";
import PageNavigation, { PageNavigationProps } from "./PageNavigation";
import { Grid } from "./ProductCards";

type GenericRenderComponentsProps = {
  components: any;
  crumbs?: any;
  title?: string;
};

const GenericRenderComponents: React.FC<GenericRenderComponentsProps> = ({ components, crumbs, title }) => {
  return (
    <>
      {components?.map((component: any, index: any) => {
        if (component["hero_banner"]) {
          return (
            <HeroBanner
              {...(component.hero_banner as HeroBannerProps)}
              crumbs={crumbs}
              key={index}
              title={title ? title : ""}
            />
          );
        }
        if (component["hero_carousel"]) {
          return <HeroCarousel {...(component.hero_carousel as HeroCarouselProps)} key={index} />;
        }
        if (component["segment_selector"]) {
          return <SegmentSelector {...(component.segment_selector as SegmentSelectorProps)} key={index} />;
        }
        if (component["on_page_navigation_bar"]) {
          return <PageNavigation {...(component.on_page_navigation_bar as PageNavigationProps)} key={index} />;
        }
        if (component["document_download"]) {
          const DocDownload = useMemo(
            () =>
              loadableVisibility(() => import("components/DocDownload"), {
                fallback: <DocDownloadSkeleton {...(component.document_download as DocDownloadProps)} />,
              }),
            [component.document_download]
          );
          return <DocDownload {...(component.document_download as DocDownloadProps)} key={index} lazyload />;
        }
        if (component["documents_download"]) {
          const DocDownload = useMemo(
            () =>
              loadableVisibility(() => import("components/DocDownload"), {
                fallback: <DocDownloadSkeleton {...(component.documents_download as DocDownloadProps)} />,
              }),
            [component.documents_download]
          );
          return <DocDownload {...(component.documents_download as DocDownloadProps)} key={index} lazyload />;
        }
        if (component["accordion"]) {
          const Accordion = useMemo(
            () =>
              loadableVisibility(() => import("components/Accordion"), {
                fallback: <AccordionSkeleton {...(component.accordion as AccordionProps)} />,
              }),
            [component.accordion]
          );
          return <Accordion {...(component.accordion as AccordionProps)} key={index} lazyload />;
        }
        if (component["findATrainer"]) {
          const FindATrainer = useMemo(
            () => loadableVisibility(() => import("components/FindATrainer")),
            [component.findATrainer]
          );
          return <FindATrainer {...(component.findATrainer as FindATrainerProps)} key={index} lazyload />;
        }
        if (component["image_with_caption"]) {
          const ImageWithCaption = useMemo(
            () => loadableVisibility(() => import("components/ImageWithCaption")),
            [component.image_with_caption]
          );
          return <ImageWithCaption {...(component.image_with_caption as ImageWithCaptionProps)} key={index} lazyload />;
        }
        if (component["news_listing_preview"]) {
          const NewsListPreview = useMemo(
            () => loadableVisibility(() => import("components/NewsList/Preview")),
            [component.news_listing_preview]
          );
          return <NewsListPreview {...(component.news_listing_preview as NewsListPreviewProps)} key={index} lazyload />;
        }
        if (component["large_degrees_banner"]) {
          // const SixtyThreeDegreesBannerLarge = useMemo(
          //   () =>
          //     loadableVisibility(() => import("components/SixtyThreeDegreesBanner/Large"), {
          //       fallback: (
          //         <SixtyThreeDegreesBannerLargeSkeleton
          //           {...(component.large_degrees_banner as DegreesBannerLargeProps)}
          //         />
          //       ),
          //     }),
          //   [component.large_degrees_banner]
          // );
          return <Large {...(component.large_degrees_banner as DegreesBannerLargeProps)} key={index} lazyload />;
        }
        if (component["medium_degrees_banner"]) {
          // const SixtyThreeDegreesBannerMedium = useMemo(
          //   () =>
          //     loadableVisibility(() => import("components/SixtyThreeDegreesBanner/Medium"), {
          //       fallback: (
          //         <SixtyThreeDegreesBannerMediumSkeleton
          //           {...(component.medium_degrees_banner as DegreesBannerMediumProps)}
          //         />
          //       ),
          //     }),
          //   [component.medium_degrees_banner]
          // );
          return <Medium {...(component.medium_degrees_banner as DegreesBannerMediumProps)} key={index} lazyload />;
        }
        if (component["small_degrees_banner"]) {
          // const SixtyThreeDegreesBannerSmall = useMemo(
          //   () =>
          //     loadableVisibility(() => import("components/SixtyThreeDegreesBanner/Small"), {
          //       fallback: (
          //         <SixtyThreeDegreesBannerSmallSkeleton
          //           {...(component.small_degrees_banner as DegreesBannerSmallProps)}
          //         />
          //       ),
          //     }),
          //   [component.small_degrees_banner]
          // );
          return <Small {...(component.small_degrees_banner as DegreesBannerSmallProps)} key={index} lazyload />;
        }
        if (component["product_cards"]) {
          const slider = component.product_cards.enable_carousel;
          if (slider && component.product_cards?.products.length > 1) {
            const ProductCardsCarousel = useMemo(
              () =>
                loadableVisibility(() => import("components/ProductCards/Carousel"), {
                  fallback: <ProductCardsSkeleton {...(component.product_cards as ProductCardsProps)} />,
                }),
              [component.product_cards]
            );
            return <ProductCardsCarousel {...(component.product_cards as ProductCardsProps)} key={index} lazyload />;
          } else {
            // const ProductCardsGrid = useMemo(
            //   () =>
            //     loadableVisibility(() => import("components/ProductCards/Grid"), {
            //       fallback: <ProductCardsSkeleton {...(component.product_cards as ProductCardsProps)} />,
            //     }),
            //   [component.product_cards]
            // );
            return <Grid {...(component.product_cards as ProductCardsProps)} key={index} />;
          }
        }
        if (component["publicationShowcase"]) {
          const PublicationShowcase = useMemo(
            () =>
              loadableVisibility(() => import("components/Publication/Showcase"), {
                fallback: (
                  <PublicationShowcaseSkeleton {...(component.publicationShowcase as PublicationShowcaseProps)} />
                ),
              }),
            [component.publicationShowcase]
          );
          return (
            <PublicationShowcase
              {...(component.publicationShowcase as PublicationShowcaseProps)}
              key={index}
              lazyload
            />
          );
        }
        if (component["related_content"]) {
          const RelatedContent = useMemo(
            () => loadableVisibility(() => import("components/RelatedContent")),
            [component.related_content]
          );
          return <RelatedContent {...(component.related_content as RelatedContentProps)} key={index} lazyload />;
        }
        if (component["stats"]) {
          const Stats = useMemo(
            () =>
              loadableVisibility(() => import("components/Stats"), {
                fallback: <StatsSkeleton {...(component.stats as StatsProps)} />,
              }),
            [component.stats]
          );
          return <Stats {...(component.stats as StatsProps)} key={index} lazyload />;
        }
        if (component["testimonials_section"]) {
          const Testimonials = useMemo(
            () =>
              loadableVisibility(() => import("components/Testimonials"), {
                fallback: <TestimonialsSkeleton {...(component.testimonials_section as TestimonialsProps)} />,
              }),
            [component.testimonials_section]
          );
          return <Testimonials {...(component.testimonials_section as TestimonialsProps)} key={index} lazyload />;
        }
        if (component["text_with_subheading"]) {
          // const TextWithSubheading = useMemo(
          //   () =>
          //     loadableVisibility(() => import("components/TextWithSubheading"), {
          //       fallback: (
          //         <TextWithSubheadingSkeleton {...(component.text_with_subheading as TextWithSubheadingProps)} />
          //       ),
          //     }),
          //   [component.text_with_subheading]
          // );
          return (
            <TextWithSubheading {...(component.text_with_subheading as TextWithSubheadingProps)} key={index} lazyload />
          );
        }
        if (component["twoColumnImage"]) {
          const TwoColumnImage = useMemo(
            () =>
              loadableVisibility(() => import("components/TwoColumnImage"), {
                fallback: <TwoColumnImageSkeleton {...(component.twoColumnImage as TwoColumnImageProps)} />,
              }),
            [component.twoColumnImage]
          );
          return <TwoColumnImage {...(component.twoColumnImage as TwoColumnImageProps)} key={index} lazyload />;
        }
      })}
    </>
  );
};
export default React.memo(GenericRenderComponents);
