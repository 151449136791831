import React from "react";
import clsx from "clsx";
import { PageReferenceProps, usePageReference } from "modules/unions/usePageReference";

import ChevronSVG from "modules/theme/icons/chevrons/chevron.svg";
import * as Button from "components/Button";
import PreloadImage from "modules/lazyload/PreloadImage";

import "components/SegmentSelector/styles.scss";

export type SegmentSelectorProps = {
  segment_one: SegmentProps;
  segment_two: SegmentProps;
  segment_three: SegmentProps;
  segment_four: SegmentProps;
};

type SegmentProps = {
  title: string;
  description: string;
  image: {
    url: string;
  };
  page_link: PageReferenceProps[];
};

const SegmentSelector: React.FC<SegmentSelectorProps> = ({ segment_one, segment_two, segment_three, segment_four }) => {
  const desktop = {
    height: 300,
    width: 600,
    aspectRatio: "2:1",
  };
  const mobile = {
    height: 70,
    width: 120,
    aspectRatio: "12:7",
  };
  const classNames = clsx("segmentSelector");
  return (
    <div className={classNames}>
      <div className="container">
        <ul>
          <li>
            <Button.PageLink slug={usePageReference(segment_one?.page_link[0])}>
              <PreloadImage alt={segment_one?.title} desktop={desktop} mobile={mobile} src={segment_one?.image?.url} />
              <div>
                <div>
                  <h2>{segment_one?.title}</h2>
                  <p>{segment_one?.description}</p>
                  <ChevronSVG />
                </div>
              </div>
            </Button.PageLink>
          </li>
          <li>
            <Button.PageLink slug={usePageReference(segment_two?.page_link[0])}>
              <PreloadImage alt={segment_two?.title} desktop={desktop} mobile={mobile} src={segment_two?.image?.url} />
              <div>
                <div>
                  <h2>{segment_two?.title}</h2>
                  <p>{segment_two?.description}</p>
                  <ChevronSVG />
                </div>
              </div>
            </Button.PageLink>
          </li>
          <li>
            <Button.PageLink slug={usePageReference(segment_three?.page_link[0])}>
              <PreloadImage
                alt={segment_three?.title}
                desktop={desktop}
                mobile={mobile}
                src={segment_three?.image?.url}
              />
              <div>
                <div>
                  <h2>{segment_three?.title}</h2>
                  <p>{segment_three?.description}</p>
                  <ChevronSVG />
                </div>
              </div>
            </Button.PageLink>
          </li>
          <li>
            <Button.PageLink slug={usePageReference(segment_four?.page_link[0])}>
              <PreloadImage
                alt={segment_four?.title}
                desktop={desktop}
                mobile={mobile}
                src={segment_four?.image?.url}
              />
              <div>
                <div>
                  <h2>{segment_four?.title}</h2>
                  <p>{segment_four?.description}</p>
                  <ChevronSVG />
                </div>
              </div>
            </Button.PageLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SegmentSelector;
