import { usePageReference } from "modules/unions/usePageReference";
import { htmlSafe, stripParagraphTags } from "modules/utils";
import React from "react";
import { ProductCardsProps } from "./types";

const ProductCardsSkeleton: React.FunctionComponent<ProductCardsProps> = ({ cta, products, text, title }) => {
  let href = cta?.page_link ? usePageReference(cta?.page_link[0]) : cta?.custom_url;
  return (
    <div style={{ visibility: "hidden" }}>
      {(title || text) && (
        <section>
          <div>
            <div>
              <h2>{title}</h2>
              {text && <p>{text}</p>}
            </div>
          </div>
        </section>
      )}
      <div>
        <div>
          <div>
            {products?.map((product: any, key: any) => {
              let cardHref = product.body?.cta?.page_link
                ? usePageReference(product.body?.cta?.page_link[0])
                : product.body?.cta?.custom_url;
              return (
                <div key={key}>
                  <div>
                    <header>
                      <div>{product.header.copy && <p>{htmlSafe(stripParagraphTags(product.header.copy))}</p>}</div>
                    </header>
                    <div>
                      {product.body.sub_heading && <h3>{product.body.sub_heading}</h3>}
                      {product.body.description && <p>{product.body.description}</p>}
                      {(cta.page_link?.length !== 0 || cta.custom_url !== "") && (
                        <a href={cardHref}>{product.body?.cta.label}</a>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {(cta.page_link?.length !== 0 || cta.custom_url !== "") && <a href={href}>{cta.label}</a>}
        </div>
      </div>
    </div>
  );
};

export default ProductCardsSkeleton;
