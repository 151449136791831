import Cookies from "universal-cookie";

export const cookies = new Cookies();

export const useSetNewsletterCookie = (numberOfDays: number) => {
  const addDays = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  const todaysDate = new Date();
  const cookieExpiresOn = addDays(todaysDate, numberOfDays);
  cookies.set("SIGN_UP_FOR_NEWSLETTER", "newsletter", { expires: cookieExpiresOn });
};

export const useGetNewsletterCookie = () => {
  return cookies.get("SIGN_UP_FOR_NEWSLETTER");
};
