import React from "react";
import { usePageReference } from "modules/unions/usePageReference";
import { PublicationShowcaseProps } from "../types";

const PublicationShowcaseSkeleton: React.FunctionComponent<PublicationShowcaseProps> = ({
  cta,
  publication,
  title,
}) => {
  let href = cta?.page_link ? usePageReference(cta?.page_link[0]) : cta?.custom_url;
  return (
    <section style={{ visibility: "hidden" }}>
      <div>
        <div>
          <h3>{title ? title : publication[0]?.title}</h3>
          {href && <a href={href}>{cta.label}</a>}
        </div>
      </div>
    </section>
  );
};

export default PublicationShowcaseSkeleton;
