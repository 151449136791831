import { useGetNewsletterCookie, useSetNewsletterCookie } from "modules/cookies";
import useModalAction from "modules/modals/hooks/useModalAction";
import { NewsletterPopupType } from "modules/newsletter/types";
import { useRef } from "react";

type UseNewsletterPopupProps = NewsletterPopupType & {
  pageOverride?: string;
};

export const useNewsletterPopup = ({
  config: { expiry_date, show_popup_global, timer },
  copy,
  submitted,
  pageOverride,
}: UseNewsletterPopupProps) => {
  const cookieExpires = expiry_date ? expiry_date : 30;
  const globalSetting = show_popup_global === "Off" ? false : true;
  const pageSettingTrue = pageOverride === "On" ? true : globalSetting;
  const show = pageOverride === "Off" ? false : pageSettingTrue;
  const modal = useRef<string | null>(null);
  const { modalShow, modalHide } = useModalAction();
  const modalCopy = {
    copy,
    submitted,
  };

  if (show) {
    if (!useGetNewsletterCookie()) {
      setTimeout(
        () => {
          import("../NewsletterModal").then((module) => {
            module.NewsletterModal({ modalCopy, modalShow, modalHide, modal });
          });
        },
        timer ? timer * 1000 : 30000 // 30 seconds
      );
      useSetNewsletterCookie(cookieExpires);
    }
  }
};
